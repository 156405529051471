/* eslint-disable import/order */
import { createApp, h, shallowRef } from 'vue';
import 'ress';
/* Once design system components need be be used, uncomment the following import and corresponding `app.use` */
import { ComponentLibrary as designSystemLibrary } from '@1trading/design-system';

import './polyfills';
import { identifiersService } from '@exchange/libs/utils/client-identifiers/src';
import { appPlatformAuthenticator, onAppStateInactive, subscribeToCapAppEvents } from '@exchange/libs/auth/service/src';
import { capApp, capIsNativePlatform, capSplashScreen } from '@exchange/libs/utils/capacitor/src';
/*
import { nativeAppCodePush } from '@exchange/libs/native-app-code-push/src';
*/
import '@exchange/libs/utils/capacitor/src/lib/cookie-workaround';
import { cookieService } from '@exchange/libs/utils/cookie/src';
import { gaService } from '@exchange/libs/utils/google-analytics/src';
import i18nService from '@exchange/libs/utils/langs-info/src/lib/i18n.service';
import { langsInfoService } from '@exchange/libs/utils/langs-info/src';
import { initMQBreakpoints } from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { requestEssentialData } from '@exchange/essential-fetches';
import { SWAppUpdateChecker } from '@exchange/libs/utils/service-worker-update-checker/src';
import { tooltipService } from '@exchange/libs/utils/tooltip/src';
import CookieHelper from '@exchange/helpers/cookie-helper';
import loadXComponents from '@exchange/libs/components/x-components/src/load-x-components';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { pixi } from '@exchange/libs/utils/pixi/src';
import Deposit from '@exchange/libs/transactions/deposit/src/lib/Deposit.vue';
import Withdraw from '@exchange/libs/transactions/withdraw/src/lib/Withdraw.vue';
import Transfer from '@exchange/libs/transactions/transfer/src/lib/Transfer.vue';

import packageJson from '../package.json';
import '@exchange/styles/main.scss';
import '@exchange/styles/hacks.css';
/* design system styles */
import '@1trading/design-system/one-trading-design-system.css';

import App from './App.vue';
import Modal from '@exchange/libs/modals/src/lib/Modal.vue';
import router from './routing/router';
import { applyLocalStorageChanges } from './local-storage-migrations';
import { modalService } from '@exchange/libs/modals/src';

import { themingInstance } from '@exchange/libs/utils/theming/src';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).version = packageJson.version;

applyLocalStorageChanges();
CookieHelper.create(identifiersService.sessionHeader, identifiersService.sessionId, undefined, process.env.VUE_APP_SUPERDOMAIN);

SWAppUpdateChecker.init({ shouldInit: !capIsNativePlatform() });
launchdarkly.identify(); /* we need this for all unauthorized users */
requestEssentialData('main');
const loadTranslations = langsInfoService.loadTranslations();

cookieService.cookieBanner.manager.load(capIsNativePlatform());
themingInstance.appTheme.apply();
pixi.initialize(themingInstance.appTheme.getCurrentThemeConfig().font['fnt-file-name']);

modalService.registerModals([
  { component: shallowRef(Deposit), name: 'Deposit', options: { isPrivate: true, canClose: true } },
  { component: shallowRef(Withdraw), name: 'Withdraw', options: { isPrivate: true, canClose: true } },
  { component: shallowRef(Transfer), name: 'Transfer', options: { isPrivate: true, canClose: true } },
]);

const runCapPrep = async () => {
  if (!capIsNativePlatform()) {
    return Promise.resolve();
  }

  subscribeToCapAppEvents();
  /* disable it because it is not used; the package has been updated and testing is required
  nativeAppCodePush.init(launchdarkly.flags['capacitor-updater']);
  launchdarkly.getWhenReady('capacitor-updater', { unauthorized: false });
  */

  const currentAppState = await capApp.getState().catch((e) => logger.error('main', 'Obtaining app state failed', e));

  if (currentAppState && !currentAppState.isActive) {
    logger.info('app is inactive => perform onAppStateInactive');
    onAppStateInactive();
  }

  try {
    const probablyKilled = await appPlatformAuthenticator.wasProbablyRecentlyKilled();

    if (probablyKilled) {
      return await appPlatformAuthenticator.wentToInactiveAt.remove();
    }

    return await Promise.resolve();
  } catch (e) {
    return Promise.resolve();
  }
};
const capPrep = runCapPrep();

const app = createApp({
  mounted: () => {
    if (capIsNativePlatform()) {
      capPrep.finally(() => capSplashScreen.hide());
    }
  },
  render: () => h(App),
}).use(i18nService.i18nInstance);

app.config.performance = false;

app.use(router);
/* design system */
// @ts-ignore it's plugin
app.use(designSystemLibrary, { autoRegLegacyComponents: true });
langsInfoService.addOnSetLanguageCallback((lang: 'en' | 'de') => designSystemLibrary.changeLocale(lang));

gaService.init(app);
tooltipService.init(app);

initMQBreakpoints(capIsNativePlatform());
loadXComponents({ app });
app.component('XModal', Modal);

Promise.all([loadTranslations]).finally(() => {
  app.mount('#app-holder');
});

const loadZendesk = () => {
  import('@exchange/libs/utils/zendesk/src').then((module) => {
    const { zendeskService } = module;

    zendeskService.init(() => langsInfoService.language.value);

    router.afterEach(zendeskService.routeGuard);
  });
};

if (!('requestIdleCallback' in window)) {
  setTimeout(() => {
    loadZendesk();
  }, 1_000);
} else {
  window.requestIdleCallback(() => {
    loadZendesk();
  });
}
